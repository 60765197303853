import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faBuilding, faCheck, faCircleInfo, faHandshake, faPlus, faXmark, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { PartnerRegistration } from "src/app/core/data/models/PartnerRegistration";
import { AppConstants } from "src/app/shared/constants/app-constants";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "work-queue-details-modal",
  templateUrl: "./work-queue-details-modal.component.html",
  styleUrls: ["./work-queue-details-modal.component.scss"],
})
export class WorkQueueDetailsModal implements OnInit {
  @Input() selectedTask: AdminTask = {} as AdminTask;
  @Input() isVisible: boolean;
  @Output() onClose = new EventEmitter<void>();

  constructor(
    private profileService: ProfileService,
    private messageService: MessageService
  ) { }


  ngOnInit(): void { }
  faPlus = faPlus;
  faCheck = faCheck;
  faXmark = faXmark;
  faBuilding = faBuilding;
  faHandshake = faHandshake;
  faCircleInfo = faCircleInfo;
  faEyeSlash = faEyeSlash;
  faEye = faEye;

  isAccepted: boolean = null;
  username: string = null;
  password: string = null;
  isError: boolean = false;
  placeholderOptions: any[] = [{ name: "Lorem ipsum" }, { name: "Dolor sit amet" }];
  companyRoleSelectedOption: string = "option1";
  billingSelectedOption: string = "option1";
  showPassword: boolean = false;

  acceptOption(option: boolean) {
    this.isAccepted = option;
  }

  selectRoleOption(option: string) {
    this.companyRoleSelectedOption = option;
  }

  submit() {
    if(!this.isAccepted){
      this.close();
    }
    if (this.username != null && this.password != null) {
      let data: PartnerRegistration = {
        userId: this.selectedTask.inviteeUserId?.toString(),
        context: this.selectedTask.partnerSystem,
        manufacturerId: this.selectedTask.inviteeCompanyId?.toString(),
        username: this.username,
        password: this.password,
        task: this.selectedTask,
      };

      this.profileService
        .completeRegistration(data, this.selectedTask.id)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            const errorMessage = error?.error?.detail ?? AppConstants.RoofingPassportCommunicationError;
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: errorMessage,
            });
            return EMPTY;
          }),
          tap(() => {
            this.close();
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Task completed.",
            });
          })
        )
        .subscribe();
    }
  }
  close() {
    this.isVisible = false;
    this.onClose.emit();
    this.username = null;
    this.password = null;
  }
}
