import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ManufacturerProfile, ThirdPartyAuth, UserData } from "src/app/core/store/app.models";
import { AppState } from "src/app/shared/services/app-state";
import { AuthContext } from "src/app/shared/services/authentication.service";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  @Output() nextClicked = new EventEmitter<void>();
  @Output() goBackClicked = new EventEmitter<void>();

  faArrowLeft = faArrowLeft;
  faCheck = faCheck;
  displayHelp: boolean = false;
  userDetails: UserData;
  profile: ManufacturerProfile;
  roofingWRXAuthorization: ThirdPartyAuth;
  eagleviewAuthorization: ThirdPartyAuth;

  constructor(
    private appState: AppState,
    private profileService: ProfileService
  ) {
    this.userDetails = appState.user;
    this.profile = appState.currentProfile;
    this.roofingWRXAuthorization = this.profile.authorizations.find((x) => x.context === AuthContext.RoofingWRX);
    this.eagleviewAuthorization = this.profile.authorizations.find((x) => x.context === AuthContext.EagleView);
  }

  ngOnInit(): void {}

  toggleHelp() {
    this.displayHelp = !this.displayHelp;
  }

  resendEmail() {}

  onConfirmClick() {
    this.profileService.completeInvitation(this.appState.currentProfile.manufacturer.id, true).subscribe({
      next: () => {
        this.nextClicked.emit();
      },
    });
  }

  onGoBackClick() {
    this.goBackClicked.emit();
  }
}
