import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ParamMap, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {Checkbox} from "primeng/checkbox";
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {Manufacturer} from "src/app/core/data/models/AppInitializationData";
import {AdminService} from "src/app/shared/services/admin.service";
import {AppState} from "src/app/shared/services/app-state";
import {faArrowUpRightFromSquare, faPen, faSave, faExclamationCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import states from "src/app/core/data/states";
import {ProfileService} from "../../../../shared/services/profile.service";
import {CompanyService} from "../../../../shared/services/company.service";
import {GetCompanyResponse} from "../../../../core/data/models/GetCompanyResponse";
import {ActivatedRoute} from '@angular/router';
import {UpdateCompanyRequest} from "../../../../core/data/models/UpdateCompanyRequest";

@Component({
  selector: "app-edit-company-profile",
  templateUrl: "./edit-company-profile.component.html",
  styleUrls: ["./edit-company-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCompanyProfileComponent implements OnInit, OnDestroy {
  @Output() onCancel = new EventEmitter();

  private destroyed$ = new ReplaySubject(1);
  busy: Subscription;
  errorMessage: string;
  isRPAdmin = this.appState.isAdminOrRPAdmin;
  profile$ = this.appState.profile$;
  checked = true;
  enableWRXJobs = "enableWRXJobs";
  enableWRX = "enableWRX";
  @ViewChild("wrxEnabled") wrxEnabled: Checkbox;
  @ViewChild("wrxJobsEnabled") wrxJobsEnabled: Checkbox;
  private _manufacturers: BehaviorSubject<Manufacturer[]> = new BehaviorSubject<Manufacturer[]>(
    this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
  );
  public manufacturers: Observable<Manufacturer[]> = this._manufacturers.asObservable();
  companyForm: FormGroup;
  states = states;
  company: GetCompanyResponse;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faPen = faPen;
  faSave = faSave;
  faTimes = faTimes;
  faExclamationCircle = faExclamationCircle;
  originalValues: {
    manufacturerId: number;
    name: string;
    description?: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    website?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhone?: string;
    roofingWRXEnabled: boolean;
    roofingWRXJobsEnabled: boolean;
    roofingWRXCompanyId: number;
  }

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private adminService: AdminService,
    private messageService: MessageService,
    private router: Router,
    private profileService: ProfileService,
    private companyService: CompanyService,
    private route: ActivatedRoute
  ) {
    this.setupForm();
  }

  get manufacturerId() {
    return this.companyForm.get("manufacturerId");
  }

  get name() {
    return this.companyForm.get("name");
  }

  get description() {
    return this.companyForm.get("description");
  }

  get street() {
    return this.companyForm.get("street");
  }

  get city() {
    return this.companyForm.get("city");
  }

  get state() {
    return this.companyForm.get("state");
  }

  get postalCode() {
    return this.companyForm.get("postalCode");
  }

  get website() {
    return this.companyForm.get("website");
  }

  get contactName() {
    return this.companyForm.get("contactName");
  }

  get contactEmail() {
    return this.companyForm.get("contactEmail");
  }

  get contactPhone() {
    return this.companyForm.get("contactPhone");
  }

  get roofingWRXCompanyId() {
    return this.companyForm.get("roofingWRXCompanyId");
  }

  get roofingWRXEnabled() {
    return this.companyForm.get("roofingWRXEnabled");
  }

  get roofingWRXJobsEnabled() {
    return this.companyForm.get("roofingWRXJobsEnabled");
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const companyId = parseInt(params.get('id'));
      if (companyId) {
        this.fetchCompanyData(companyId);
      }
    });

    this.appState.profile$.subscribe((profile) => {
      this._manufacturers.next(
        this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setupForm() {
    this.companyForm = this.fb.group({
      manufacturerId: [0, Validators.required],
      name: ["", [Validators.required, Validators.maxLength(50)]],
      description: [""],
      street: ["", Validators.required],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
      website: ["", Validators.maxLength(50)],
      contactName: ["", Validators.maxLength(50)],
      contactEmail: ["", [Validators.email, Validators.maxLength(50)]],
      contactPhone: ["", Validators.maxLength(50)],
      roofingWRXEnabled: [true],
      roofingWRXJobsEnabled: [true],
      roofingWRXCompanyId: [0, Validators.pattern(/\d+/)],
    });

    this.originalValues = {...this.companyForm.value}
  }

  patchForm(company: GetCompanyResponse) {
    this.companyForm.patchValue({
      manufacturerId: company.id,
      name: company.name,
      description: company.description,
      street: company.street,
      city: company.city,
      postalCode: company.postalCode,
      state: company.state,
      website: company.website,
      contactName: company.contactName,
      contactEmail: company.contactEmail,
      contactPhone: company.contactPhone,
      roofingWRXEnabled: true,
      roofingWRXJobsEnabled: true,
      roofingWRXCompanyId: company.roofingWRXCompanyId,
    });

    this.companyForm.markAsPristine();
    this.companyForm.markAsUntouched();

    if (company) {
      this.companyForm.markAllAsTouched();
    }
  }

  fetchCompanyData(companyId: number) {
    this.companyService.getCompany(companyId).subscribe(company => {
      this.company = company;
      this.patchForm(company);
    });
  }

  setState(value: string): void {
    this.companyForm.get('state')?.setValue(value);
  }

  onChecked(checkboxType: Checkbox): void {
    if (checkboxType.value === this.enableWRX) {
      checkboxType.checked() ? this.roofingWRXEnabled.setValue(true) : this.roofingWRXEnabled.setValue(false);
    }
    if (checkboxType.value === this.enableWRXJobs) {
      checkboxType.checked() ? this.roofingWRXJobsEnabled.setValue(true) : this.roofingWRXJobsEnabled.setValue(false);
    }
  }

  save() {
    let updateCompanyRequest: UpdateCompanyRequest = {
      id: this.company.id,
      name: this.name.value,
      description: this.description.value,
      street: this.street.value,
      city: this.city.value,
      postalCode: this.postalCode.value,
      state: this.state.value,
      website: this.website.value,
      contactName: this.contactName.value,
      contactEmail: this.contactEmail.value,
      contactPhone: this.contactPhone.value,
      roofingWRXEnabled: this.roofingWRXEnabled.value,
      roofingWRXJobsEnabled: this.roofingWRXJobsEnabled.value,
      roofingWRXCompanyId: this.roofingWRXCompanyId.value,
    };

    if (this.roofingWRXCompanyId.dirty && !this.roofingWRXCompanyId.value) {
      updateCompanyRequest.roofingWRXCompanyId = null;
    }

    if (this.companyForm.valid) {
      this.adminService
        .updateCompany(updateCompanyRequest)
        .pipe(
          map((x: any) => {
            if (!x.error?.message && x.manufacturer) {
              this._manufacturers.next(
                this.appState.isRPAdmin ? this.appState.managedManufacturers : this.appState.profileManufacturer
              );
              this.manufacturerId.setValue(x.manufacturer.id);

              this.profileService.validateProfile().subscribe(() => this.messageService.add({
                  severity: "success",
                  summary: "Success",
                  detail: "Company profile successfully updated.",
                })
              );

              this.originalValues = this.companyForm.value;
              this.companyForm.markAsPristine();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: x.errors.detail ?? "",
              });
            }
          })
        )
        .subscribe();
    }
  }

  isDisabled(): boolean {
    const currentValues = this.companyForm.value;
    const valuesChanged =
      currentValues.manufacturerId !== this.originalValues.manufacturerId ||
      currentValues.name !== this.originalValues.name ||
      currentValues.description !== this.originalValues.description ||
      currentValues.street !== this.originalValues.street ||
      currentValues.city !== this.originalValues.city ||
      currentValues.state !== this.originalValues.state ||
      currentValues.postalCode !== this.originalValues.postalCode ||
      currentValues.website !== this.originalValues.website ||
      currentValues.contactName !== this.originalValues.contactName ||
      currentValues.contactEmail !== this.originalValues.contactEmail ||
      currentValues.contactPhone !== this.originalValues.contactPhone ||
      currentValues.roofingWRXEnabled !== this.originalValues.roofingWRXEnabled ||
      currentValues.roofingWRXJobsEnabled !== this.originalValues.roofingWRXJobsEnabled ||
      currentValues.roofingWRXCompanyId !== this.originalValues.roofingWRXCompanyId;

    return !(this.companyForm.valid && this.companyForm.dirty && valuesChanged);
  }


  returnToProjectList() {
    return this.router.navigate(["../jobs"]);
  }

  redirectToCompanies() {
    return this.router.navigate(["admin/companies"]);
  }
}
