import { NgModule } from "@angular/core";
import { AdminComponent } from "./admin.component";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { AdminUsersComponent } from "./admin-users/admin-users.component";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminReportingComponent } from "./admin-reporting/admin-reporting.component";
import { NgxsModule } from "@ngxs/store";
import { CoreModule } from "src/app/core/core.module";
import { AdminService } from "src/app/shared/services/admin.service";
import { MessageModule } from "primeng/message";
import { CalendarModule } from "primeng/calendar";
import { OrderStatisticsComponent } from "./admin-reporting/order-statistics/order-statistics.component";
import { WorkQueueComponent } from "./work-queue/work-queue.component";
import { EditUserComponent } from "./admin-users/edit-user/edit-user.component";
import { EditCompanyComponent } from "./edit-company/edit-company.component";
import { EditCompanyProfileComponent } from "./edit-company/edit-company-profile/edit-company-profile.component";
import { EditCompanyStructureComponent } from "./edit-company/edit-company-structure/edit-company-structure.component";
import { CompaniesListComponent } from "./companies-list/companies-list.component";
import { WorkQueueDetailsModal } from "./work-queue/work-queue-details/work-queue-details-modal.component";

@NgModule({
  declarations: [
    AdminComponent,
    AdminSettingsComponent,
    AdminUsersComponent,
    AdminReportingComponent,
    OrderStatisticsComponent,
    WorkQueueComponent,
    WorkQueueDetailsModal,
    EditUserComponent,
    EditCompanyComponent,
    EditCompanyProfileComponent,
    EditCompanyStructureComponent,
    CompaniesListComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    AdminRoutingModule,
    MessageModule,
    CalendarModule,
    NgxsModule.forFeature([]),
  ],
  exports: [
    AdminComponent,
    AdminSettingsComponent,
    AdminUsersComponent,
    AdminReportingComponent,
    OrderStatisticsComponent,
    EditUserComponent,
    EditCompanyComponent,
    EditCompanyProfileComponent,
    EditCompanyStructureComponent,
    CompaniesListComponent,
  ],
  providers: [AdminService],
})
export class AdminModule {}
