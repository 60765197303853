import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "option-box",
  templateUrl: "./option-box.component.html",
  styleUrls: ["./option-box.component.scss"],
})
export class OptionBoxComponent implements OnInit {
  constructor() { }

  faCheck = faCheck;

  ngOnInit(): void { }
  @Input() id: number = 0;
  @Input() selectedValue: string;
  @Input() name: string;
  @Input() value: any;
  @Input() isRadio: boolean = true;
  @Input() checked: boolean = false;
  @Input() isButton: boolean = false;
  @Input() disabled: boolean = false;
  @Input() label: string;
  @Input() subtitle: string;
  @Input() icon: string = null;
  @Input() iconColor: string = null;
  @Input() image: string = null;
  @Input() smallTitle: boolean = false;
  @Input() styleClass: string = null;
  @Output() optionSelected = new EventEmitter<any>();

  selectOption() {
    this.changeValue();
    this.optionSelected.emit(this.value);
  }
  
  changeValue() {
    this.value = !this.value;
  }
}
