import { AuthContext } from "src/app/shared/services/authentication.service";
import { AdminTask } from "./AdminTask";

export interface PartnerRegistration {
  username?: string;
  password?: string;
  lastCompletedTrainingStep?: TrainingStep;
  lastCompletedAdminTrainingStep?: AdminTrainingStep;
  context: AuthContext;
  userId: string;
  manufacturerId: string;
  task: AdminTask;
}

export enum TrainingStep {
  NotStarted = 0,
  RPOnboardInviteSent = 1,
  RPOnboardScheduled = 2,
  RPOnboardInitiated = 3,
  RPOnboardComplete = 4,
}

export enum AdminTrainingStep {
  NotStarted = 0,
  UploadMaterials = 1,
  ConfigureAssemblies = 2,
  ConfigureOutputs = 3,
  VerifyTakeOffs = 4,
  AdminTrainingComplete = 5,
}
