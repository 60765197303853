import {Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {Table} from "primeng/table";
import {
  faCircleNodes,
  faEllipsis,
  faEllipsisV,
  faPen,
  faPlus,
  faSitemap,
  faToggleOff,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {AppState} from "src/app/shared/services/app-state";
import {CompanyService} from "src/app/shared/services/company.service";
import {GetCompaniesResponse} from "../../../core/data/models/GetCompaniesResponse";

@Component({
  selector: "companies-list",
  templateUrl: "./companies-list.component.html",
  styleUrls: ["./companies-list.component.scss"],
})
export class CompaniesListComponent implements OnInit {
  @ViewChild("companiesTable") companiesTable: Table;

  constructor(private router: Router, private appState: AppState, private companyService: CompanyService) {
  }

  protected pageSize: number = 10;
  protected totalCount: number = 10;
  protected totalPages: number = 1;
  protected pageNumber: number = 1;

  onPageChange(page: number) {
    this.pageNumber = page;
    this.getCompanies();
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.getCompanies();
  }

  ngOnInit(): void {
    this.getCompanies();
  }

  companies: GetCompaniesResponse[] = [];
  faEllipsisV = faEllipsisV;
  faEllipsis = faEllipsis;
  faPlus = faPlus;
  faPen = faPen;
  faTrash = faTrash;
  faSitemap = faSitemap;
  faCircleNodes = faCircleNodes;
  faToggleOff = faToggleOff;

  redirectTo(url: string, id?: string) {
    return this.router.navigate([`admin/${url}/${id ?? ''}`]);
  }

  getCompanies() {
    this.companyService.getCompanies(this.pageNumber, this.pageSize).subscribe(paginatedResponse => {
      this.companies = paginatedResponse.data;
      this.totalCount = paginatedResponse.totalCount;
      this.totalPages = paginatedResponse.totalPages;
      this.pageNumber = paginatedResponse.pageNumber;
    })
  }

  areFiltersApplied(): boolean {
    return this.companiesTable ? !!this.companiesTable.sortField : false;
  }

  clearFilters() {
    this.companiesTable.filters = {};
    this.companiesTable.sortField = undefined;
    this.companiesTable.reset();

    this.getCompanies();
  }
}
