import { Component, OnInit } from "@angular/core";
import { faArrowRight, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { AdminTask } from "src/app/core/data/models/AdminTask";
import { TaskService } from "src/app/shared/services/task.service";
@Component({
  selector: "work-queue",
  templateUrl: "./work-queue.component.html",
  styleUrls: ["./work-queue.component.scss"],
})
export class WorkQueueComponent implements OnInit {
  constructor(private taskService: TaskService) { }

  tasks: AdminTask[] = [];
  faArrowRight = faArrowRight;
  faBuilding = faBuilding;
  isVisible: boolean = false;
  selectedTask: AdminTask = {} as AdminTask;

  ngOnInit(): void {
    this.loadTasks();
  }

  loadTasks(): void {
    this.taskService.getTasks().subscribe({
      next: (tasks) => {
        this.tasks = tasks;
        this.tasks.forEach((x) => (x.typeDisplay = x.type.split(/(?=[A-Z])/).join(" ")));

        // this.newCompanyCount = tasks.filter((x) => x.typeId === AdminTaskTypeIds.NewCompany).length;
        // this.newUserCount = tasks.filter((x) => x.typeId === AdminTaskTypeIds.NewUser).length;
      },
      error: (err) => { },
    });
  }

  viewTask(task: AdminTask) {
    this.selectedTask = task;
    this.isVisible = true;
  }
}
