<div *ngIf="user" class="container container_steps">
  <div class="pageHeading pageHeading_center columnMobileOnly">
    <h2 class="pageTitle pageTitle_steps title2 my-0">Edit user</h2>
    <div class="flex flex-wrap gap-3 mx-4 md:mx-0">
      <button pButton *ngIf="!user.completionDate" class="outline small" type="button" (click)="resendRPInvitation()">
        <fa-icon [icon]="faEnvelope"></fa-icon>
        Resend Invite
      </button>
      <button pButton *ngIf="!isUserInactive()" class="danger outline small" type="button" (click)="deactivateUser()">
        <fa-icon [icon]="faToggleOff"></fa-icon>
        Deactivate
      </button>
    </div>
  </div>

  <section class="flex align-items-center justify-content-between flex-wrap gap-3 mb-3 mx-4 md:mx-0">
    <h2 class="srOnly">Status</h2>
    <div class="flex align-items-center gap-3">
      <img
        src="assets/img/placeholder/userProfilePicPlaceholder.png"
        alt=""
        class="w-2rem h-2rem border-1 border-circle border-outline"
      />
      <p class="text-lg">{{ user.firstName }} {{ user.lastName }}</p>
    </div>
    <div class="flex flex-wrap gap-2">
      <status-badge
        [color]="user.status == 'Active' || user.status == 'Accepted' ? 'success' : 'danger'"
        [text]="user.status"
      ></status-badge>
      <status-badge
        [color]="user.credentialsCreated ? 'success' : 'danger'"
        [style]="'outline'"
        [icon]="faEnvelope"
        [text]="user.credentialsCreated ? 'Invite sent' : 'Invite not sent'"
      ></status-badge>
      <status-badge
        [color]="user.completionDate !== 'null' && user.completionDate !== '' ? 'success' : 'warning'"
        [style]="'outline'"
        [icon]="user.completionDate !== 'null' && user.completionDate !== '' ? faCheck : faQuestion"
        [text]="user.completionDate !== 'null' && user.completionDate !== '' ? 'Invite accepted' : 'Invite pending'"
      ></status-badge>
    </div>
  </section>

  <section class="card card_border card_border_noMobile p-0 border-bottom-none">
    <div class="flex justify-content-between flex-wrap gap-3 my-0 py-3 px-4">
      <h2 class="srOnly">Invitation status</h2>
      <p class="">
        <span class="label color_light">Invited</span>
        <span class="text-sm">{{ user.invited ? (user.invited | date: "M/d/yyyy H:mm") : "N/A" }}</span>
      </p>
      <p class="">
        <span class="label color_light">Invitation redeemed</span>
        <span class="text-sm">{{
          user.invitationRedeemed ? (user.invitationRedeemed | date: "M/d/yyyy H:mm") : "N/A"
        }}</span>
      </p>
      <p class="">
        <span class="label color_light">License agreement</span>
        <span class="text-sm">{{
          user.licenseAgreement ? (user.licenseAgreement | date: "M/d/yyyy H:mm") : "N/A"
        }}</span>
      </p>
      <p class="">
        <span class="label color_light">Completion</span>
        <span class="text-sm">{{ user.completion ? (user.completion | date: "M/d/yyyy H:mm") : "N/A" }}</span>
      </p>
      <p class="">
        <span class="label color_light">Invited by</span>
        <span class="text-sm">{{ user.invitedBy || "N/A" }}</span>
      </p>
    </div>
  </section>

  <div class="card card_border card_border_noMobile">
    <form [formGroup]="userForm">
      <section class="mb-2">
        <h2 class="srOnly">Personal information</h2>
        <div class="grid my-0">
          <div class="col-12 md:col-6 lg:col-4 py-0">
            <div class="inputCont">
              <label class="label" for="firstName">First name</label>
              <input
                type="text"
                name="firstName"
                formControlName="firstName"
                id="firstName"
                class="w-full"
                placeholder="First name"
                required
                pInputText
              />
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-4 py-0">
            <div class="inputCont">
              <label class="label" for="lastName">Last name</label>
              <input
                type="text"
                name="lastName"
                formControlName="lastName"
                id="lastName"
                class="w-full"
                placeholder="Last name"
                pInputText
              />
            </div>
          </div>
        </div>
        <div class="grid my-0">
          <div class="col-12 lg:col-8 py-0">
            <div class="inputCont">
              <label class="label" for="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                formControlName="email"
                class="w-full"
                placeholder="Email"
                pInputText
                disabled
              />
            </div>
          </div>
          <div class="col-12 lg:col-4 py-0">
            <div class="inputCont">
              <label class="label" for="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                formControlName="phone"
                class="w-full"
                placeholder="Phone"
                pInputText
                [disabled]="!isRPAdmin"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="mb-2" *ngIf="isRPAdmin">
        <h2 class="title3 mb-3">Training status</h2>
        <div class="grid my-0">
          <div class="col-12 lg:col-6 py-0">
            <div class="inputCont">
              <label for="trainingStatusId" class="label">RP onboard training</label>
              <p-dropdown
                inputId="trainingStatusId"
                formControlName="trainingStatusId"
                [options]="trainingSteps"
                optionLabel="label"
                optionValue="value"
                styleClass="w-full"
                inputStyleClass="w-full"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-3" *ngIf="isRPAdmin">
        <h2 class="title3 mb-3">Integrations</h2>
        <div class="grid my-0">
          <div class="col-12 md:col-4 py-0">
            <div class="card card_paddingSmall card_border mb-3">
              <div class="flex align-items-center gap-2 mb-2">
                <img src="assets/img/icons/source_sb.png" alt="" class="w-2rem" />
                <h3 class="text-sm font-semibold">SmartBuild</h3>
              </div>
              <p class="text-xs mb-3">Credentials created: {{ user.smartBuildRegistrationCompleted || "N/A" }}</p>
              <div class="flex align-items-center justify-content-between flex-wrap gap-3">
                <status-badge
                  [color]="user.smartBuild ? 'success' : 'danger'"
                  [text]="user.smartBuild ? 'Token present' : 'Token missing'"
                ></status-badge>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-4 py-0">
            <div class="card card_paddingSmall card_border mb-3">
              <div class="flex align-items-center gap-2 mb-2">
                <img src="assets/img/icons/source_ev.png" alt="" class="w-2rem" />
                <h3 class="text-sm font-semibold">EagleView</h3>
              </div>
              <p class="text-xs mb-3">Credentials created: {{ user.eagleViewRegistrationCompleted || "N/A" }}</p>
              <div class="flex align-items-center justify-content-between flex-wrap gap-3">
                <status-badge
                  [color]="user.eagleView ? 'success' : 'danger'"
                  [text]="user.eagleView ? 'Token present' : 'Token missing'"
                ></status-badge>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-4 py-0">
            <div class="card card_paddingSmall card_border mb-3">
              <div class="flex align-items-center gap-2 mb-2">
                <img src="assets/img/icons/source_rw.png" alt="" class="w-2rem" />
                <h3 class="text-sm font-semibold">Roofing WRX</h3>
              </div>
              <p class="text-xs mb-3">Credentials created: {{ user.roofingWRXRegistrationCompleted || "N/A" }}</p>
              <div class="flex align-items-center justify-content-between flex-wrap gap-3">
                <status-badge
                  [color]="user.roofingWRX ? 'success' : 'danger'"
                  [text]="user.roofingWRX ? 'Token present' : 'Token missing'"
                ></status-badge>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-2" *ngIf="isRPAdmin">
        <h2 class="title3 mb-3">Role</h2>
        <div class="optionsCont optionsCont_3col mb-4">
          <option-box
            [id]="'userRoleOption_1'"
            name="userRole"
            value="Normal"
            [checked]="userForm.value.userRoles[0] !== UserRole.RPAdmin"
            [selectedValue]="userRole"
            (optionSelected)="selectRoleOption('Normal')"
            [icon]="faUser"
            label="Common user"
            [smallTitle]="true"
          >
            <span
              >These are regular users of the platform, with privileges limited to themselves and their companies.</span
            >
          </option-box>
          <option-box
            [id]="'userRoleOption_2'"
            name="userRole"
            value="RPAdmin"
            [checked]="userForm.value.userRoles[0] === UserRole.RPAdmin"
            [selectedValue]="userRole"
            (optionSelected)="selectRoleOption('RPAdmin')"
            [icon]="faCrown"
            label="RP Administrator"
            [smallTitle]="true"
          >
            <span
              >These users have access to the administration module, and can control the entirety of Roofing
              Passport.</span
            >
          </option-box>
        </div>
      </section>

      <section *ngIf="relatedCompanies?.length > 0">
        <h2 class="title3 mb-3">{{ relatedCompanies?.length > 1 ? "Companies" : "Company" }}</h2>
        <p class="text-sm mb-4" *ngIf="isRPAdmin">
          This user is related to <span class="font-semibold">{{ relatedCompanies.length }}</span>
          {{ relatedCompanies?.length > 1 ? "Companies" : "Company" }}.
        </p>
        <p class="text-sm mb-4" *ngIf="!isRPAdmin">Edit this user's relationship with your company:</p>

        <div class="inputCont" *ngIf="isRPAdmin">
          <label for="userInput_selectedCompany" class="label">Company</label>
          <p-dropdown
            inputId="userInput_selectedCompany"
            [options]="relatedCompanies"
            optionLabel="name"
            [(ngModel)]="selectedCompany"
            [ngModelOptions]="{ standalone: true }"
            name="selectedCompany"
            [filter]="true"
            class="w-full"
            placeholder="Select company"
          ></p-dropdown>
        </div>
        <section *ngIf="selectedCompany">
          <h3 class="srOnly">Selected company details</h3>
          <section class="mb-4">
            <h4 class="title4 mb-3">Relationship type</h4>
            <div *ngIf="selectedCompany.type == 'employee'" class="card card_border card_paddingSmall">
              <div class="flex align-items-center gap-3 mb-3">
                <fa-icon [icon]="faBuilding"></fa-icon>
                <p class="text-sm font-semibold">Employee</p>
              </div>
              <p class="text-xs">
                Permanent employees act in behalf of their company, manage their jobs and depend on it for billing.
              </p>
            </div>
            <div *ngIf="selectedCompany.type == 'invited'" class="card card_border card_paddingSmall">
              <div class="flex align-items-center gap-3 mb-3">
                <fa-icon [icon]="faHandshakeAlt"></fa-icon>
                <p class="text-sm font-semibold">Invited</p>
              </div>
              <p class="text-xs">
                The inviting company can absorb the cost of quotes made by invited users for a limited amount of jobs or
                time.
              </p>
            </div>
          </section>
          <section class="inputCont">
            <h4 class="srOnly">Branch</h4>
            <label for="userInput_branch" class="label">Branch</label>
            <p-dropdown
              inputId="userInput_branch"
              [options]="placeholderOptions"
              optionLabel="name"
              [filter]="true"
              class="w-full"
              placeholder="Select branch"
            ></p-dropdown>
          </section>
          <section *ngIf="selectedCompany.type == 'employee'">
            <h4 class="title4 mb-3">Company role</h4>
            <div class="optionsCont mb-4">
              <option-box
                [id]="'userCompanyRoleOption_1'"
                name="userCompanyRole"
                value="option1"
                [selectedValue]="companyRoleSelectedOption"
                (optionSelected)="selectOption('option1', 2)"
                [checked]="true"
                [icon]="faScrewdriverWrench"
                label="Normal"
                [smallTitle]="true"
              >
                <span>Contractors and regular employees. They can create and manage their own jobs only.</span>
              </option-box>
              <option-box
                [id]="'userCompanyRoleOption_2'"
                name="userCompanyRole"
                value="option2"
                [selectedValue]="companyRoleSelectedOption"
                (optionSelected)="selectOption('option2', 2)"
                [icon]="faBriefcase"
                label="Salesperson"
                [smallTitle]="true"
              >
                <span>In addition to managing jobs, they are responsible for sending quotes and invoices.</span>
              </option-box>
              <option-box
                [id]="'userCompanyRoleOption_3'"
                name="userCompanyRole"
                value="option3"
                [selectedValue]="companyRoleSelectedOption"
                (optionSelected)="selectOption('option3', 2)"
                [icon]="faCrown"
                label="Administrator"
                [smallTitle]="true"
              >
                <span
                  >They have access to the company settings, where they can manage all jobs, billing details, invite
                  users, and more.</span
                >
              </option-box>
            </div>
          </section>
          <section *ngIf="selectedCompany.type == 'invited'" class="">
            <h4 class="title4">Billing</h4>
            <div class="mb-3">
              <div class="inputCont_check inputCont_check_inline">
                <p-radioButton
                  inputId="userInput_billing_none"
                  name="userInput_billing"
                  value="none"
                  [(ngModel)]="billingSelectedOption"
                ></p-radioButton>
                <label for="userInput_billing_none" class="label_check">None</label>
              </div>
              <div class="inputCont_check inputCont_check_inline">
                <p-radioButton
                  inputId="userInput_billing_time"
                  name="userInput_billing"
                  value="time"
                  [(ngModel)]="billingSelectedOption"
                ></p-radioButton>
                <label for="userInput_billing_time" class="label_check">Time</label>
              </div>
              <div class="inputCont_check inputCont_check_inline">
                <p-radioButton
                  inputId="userInput_billing_jobs"
                  name="userInput_billing"
                  value="jobs"
                  [(ngModel)]="billingSelectedOption"
                ></p-radioButton>
                <label for="userInput_billing_jobs" class="label_check">Jobs</label>
              </div>
              <div class="inputCont_check inputCont_check_inline">
                <p-radioButton
                  inputId="userInput_billing_unlimited"
                  name="userInput_billing"
                  value="unlimited"
                  [(ngModel)]="billingSelectedOption"
                ></p-radioButton>
                <label for="userInput_billing_unlimited" class="label_check">Unlimited</label>
              </div>
            </div>
            <div>
              <p *ngIf="billingSelectedOption == 'none'" class="billingInfoText">
                The inviting company will not absorb the cost of quotes made by the user.
              </p>
              <p *ngIf="billingSelectedOption == 'unlimited'" class="billingInfoText">
                The inviting company will absorb the cost of quotes made by the user with no restrictions.
              </p>
              <label
                *ngIf="billingSelectedOption == 'time' || billingSelectedOption == 'jobs'"
                class="billingAmountLabel"
              >
                <span class="billingInfoText"
                  >The inviting company will absorb the cost of quotes made by the user for:</span
                >
                <div class="inputCont flex gap-3 align-items-center">
                  <input pInputText id="userInput_billingLimit" class="max-w-8rem" type="number" placeholder="0" />
                  <span *ngIf="billingSelectedOption == 'time'" class="label billingAmountLabel__end"
                    >days (since account activation)</span
                  >
                  <span *ngIf="billingSelectedOption == 'jobs'" class="label billingAmountLabel__end">jobs</span>
                </div>
              </label>
              <alert
                *ngIf="billingSelectedOption == 'time' || billingSelectedOption == 'jobs'"
                color="main"
                [icon]="faInfoCircle"
                [label]="billingSelectedOption == 'time' ? '3 days left as of now.' : '3 jobs left as of now.'"
              >
              </alert>
            </div>
          </section>
        </section>
      </section>

      <section class="footActionsCont spaced mt-5">
        <button pButton class="main fullMobile" type="button" (click)="saveUser()">
          <fa-icon [icon]="faSave"></fa-icon>
          Save changes
        </button>
        <button pButton class="default outline fullMobile" type="button" (click)="redirectToAdminPanel()">
          <fa-icon [icon]="faTimes"></fa-icon>
          Cancel
        </button>
      </section>
    </form>
  </div>
</div>
