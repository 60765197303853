import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {AppState} from './shared/services/app-state';
import {ProfileStatus} from './core/data/models/AppInitializationData';

@Injectable({
    providedIn: 'root'
})
export class SetupGuard implements CanActivate {

    constructor(
        private appState: AppState,
        private router: Router
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.appState.profile$.pipe(
            map(profile => profile.status === ProfileStatus.Accepted || this.router.createUrlTree(['/register-account'])),
            catchError(() => of(this.router.createUrlTree(['/register-account'])))
        );
    }
}
