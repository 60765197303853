<div class="container container_steps">
  <div class="pageHeading pageHeading_center columnMobileOnly">
    <h1 class="pageTitle pageTitle_steps title2 my-0">Create new job</h1>

    <p *ngIf="representedUser && representedUser.name !== null" class="flex align-items-center gap-2 mx-4 md:mx-0">
      <span class="text-xs">Create for:</span>
      <status-badge color="mono" [icon]="faUser" [text]="representedUser.name"></status-badge>
    </p>
  </div>

  <p-accordion *ngIf="!completedStep[0] || !completedStep[1] || !completedStep[2]" [multiple]="false">
    <!-- Step 1 -->
    <step-card
      id="jobStep_1"
      number="1"
      label="Data source"
      [selected]="activeStep[0]"
      [textSelected]="'Pick the method you want to use to obtain dimensions data:'"
      [completed]="completedStep[0]"
      [textCompleted]="getTextCompletedStep1()"
      [iconCompleted]="iconCompleted"
    >
      <h2 class="srOnly">Data source</h2>

      <section class="optionsCont mb-4">
        <h3 class="srOnly">Select source</h3>
        <option-box
          [id]="'jobDataSourceOption_1'"
          name="jobDataSource"
          value="option1"
          [selectedValue]="step1SelectedOption"
          (optionSelected)="selectJobDataSource(1)"
          [checked]="true"
          [icon]="faLocationDot"
          label="Address"
        >
          <span>Obtain dimensions data from any location based on accurate aerial views provided by EagleView.</span>
        </option-box>
        <option-box
          [id]="'jobDataSourceOption_2'"
          name="jobDataSource"
          value="option2"
          [selectedValue]="step1SelectedOption"
          (optionSelected)="selectJobDataSource(2)"
          [icon]="faFile"
          label="XML File"
        >
          <span>Bring your own XML roofing geometry file from a third party.</span>
        </option-box>
        <option-box
          [id]="'jobDataSourceOption_3'"
          name="jobDataSource"
          value="option3"
          [selectedValue]="step1SelectedOption"
          (optionSelected)="selectJobDataSource(3)"
          [icon]="faMap"
          label="Blueprints"
          subtitle="& other documents"
        >
          <span
            >Upload any plans and documents related to your project. This data source is only supported by Roofing
            WRX.</span
          >
        </option-box>
      </section>

      <section class="grid" *ngIf="step1SelectedOption === 'option1' && isEagleViewActive">
        <h3 class="srOnly">Address settings</h3>
        <div class="col-12 md:col-6" [formGroup]="newJobLocation">
          <div class="inputCont">
            <label for="jobInput_jobName1" class="label">Job name</label>
            <input
              pInputText
              id="jobInput_jobName1"
              type="text"
              name="name"
              class="w-full"
              placeholder="Job name"
              formControlName="name"
              (blur)="nameValidation('Location')"
              (input)="jobNameExists = false"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobName1"
              *ngIf="locationFormJobName.invalid && (locationFormJobName.touched || locationFormJobName.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              <ng-container *ngIf="locationFormJobName.errors?.maxlength; else defaultError">
                Job name should not exceed 29 characters.
              </ng-container>
              <ng-template #defaultError> Please enter a job name </ng-template>
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_jobType1" class="label">Job Type</label>
            <p-dropdown
              [options]="jobTypes"
              optionLabel="name"
              class="w-full"
              placeholder="Select job type"
              formControlName="jobType"
              optionValue="name"
              id="jobInput_jobType1"
            ></p-dropdown>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_jobType1"
              *ngIf="locationFormJobType.invalid && (locationFormJobType.touched || locationFormJobType.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please select a job type</label
            >
          </div>
          <div class="inputCont">
            <label for="jobInput_address" class="label">Address</label>
            <input
              pInputText
              id="jobInput_address"
              type="text"
              name="middletName"
              class="w-full"
              placeholder="Address"
              formControlName="address"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_address"
              *ngIf="locationFormAddress.invalid && (locationFormAddress.touched || locationFormAddress.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter an address</label
            >
          </div>
          <div class="inputCont">
            <label for="jobInput_state" class="label">State/province</label>
            <p-dropdown
              [options]="stateList"
              optionLabel="name"
              class="w-full"
              placeholder="Select state"
              formControlName="state"
              optionValue="name"
              id="jobInput_state"
              optionValue="abbreviation"
            ></p-dropdown>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_state"
              *ngIf="locationFormState.invalid && (locationFormState.touched || locationFormState.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a state</label
            >
          </div>
          <div class="inputCont">
            <label for="jobInput_city" class="label">City</label>
            <input
              pInputText
              id="jobInput_city"
              class=""
              type="text"
              name="middletName"
              class="w-full"
              placeholder="City"
              formControlName="city"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_city"
              *ngIf="locationFormCity.invalid && (locationFormCity.touched || locationFormCity.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a city
            </label>
          </div>
          <div class="inputCont">
            <label for="jobInput_ZIPCode" class="label">ZIP code</label>
            <input
              pInputText
              id="jobInput_ZIPCode"
              type="text"
              name="middletName"
              formControlName="zip"
              class="w-full max-w-10rem"
              placeholder="ZIP code"
            />
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_ZIPCode"
              *ngIf="locationFormZip.invalid && (locationFormZip.touched || locationFormZip.dirty)"
            >
              <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              Please enter a ZIP code
            </label>
          </div>
          <div class="inputCont">
            <p-checkbox
              inputId="jobInput_changedRecentCheck"
              name="groupname"
              [binary]="true"
              formControlName="changesInLast4Years"
            ></p-checkbox>
            <label for="jobInput_changedRecentCheck" class="label_check"> Changed in the last 4 years </label>
          </div>
          <button pButton type="button" class="outline" (click)="findAddress()">
            <fa-icon [icon]="faSearch"></fa-icon>
            Locate address
          </button>
          <div class="pt-4">
            <p-checkbox
              inputId="jobInput_processManuallyAddress"
              name="groupname"
              [binary]="true"
              formControlName="processManually"
            ></p-checkbox>
            <label for="jobInput_processManuallyAddress" class="label_check">Process Manually</label>
            <p class="text-xs max-w-24rem pt-2 text-light">
              By choosing this option, your job/order will be sent to RoofingWRX for manual processing and verification
              of the output.
            </p>
          </div>
        </div>
        <div class="col-12 md:col-6">
          <iframe
            id="pictometry_ipa"
            [src]="pictometryUrl | safe: 'resourceUrl'"
            height="100%"
            width="100%"
            style="border: 0px"
            title="EagleView Map"
          ></iframe>
        </div>
      </section>

      <section *ngIf="step1SelectedOption === 'option1' && !isEagleViewActive">
        <h3 class="srOnly">Address settings</h3>
        <app-service-item
          [label]="'EagleView'"
          [description]="
            'You need an EagleView account in order to use addresses as a data source. Please create or link an existing EagleView account to continue, or try using a different data source.'
          "
          [disclaimer]="'At this time, EagleView provides data for buildings in the United States and Canada only.'"
          [logo]="'assets/img/icons/source_ev.png'"
          [isServiceAvailable]="true"
          [isBanner]="true"
        >
        </app-service-item>
      </section>

      <section *ngIf="step1SelectedOption === 'option2'">
        <h3 class="srOnly">XML settings</h3>
        <div class="grid">
          <div class="col-12 md:col-6" [formGroup]="newJobXML">
            <div class="inputCont">
              <label for="jobInput_jobName2" class="label">Job name</label>
              <input
                pInputText
                id="jobInput_jobName2"
                type="text"
                name="jobName"
                class="w-full"
                formControlName="name"
                placeholder="Job name"
                (blur)="nameValidation('XML')"
                (input)="jobNameExists = false"
              />
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobName2"
                *ngIf="xmlFormJobName.invalid && (xmlFormJobName.touched || xmlFormJobName.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                <ng-container *ngIf="xmlFormJobName.errors?.maxlength; else defaultError">
                  Job name should not exceed 29 characters.
                </ng-container>
                <ng-template #defaultError> Please enter a job name </ng-template>
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_jobType2" class="label">Job Type</label>
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType2"
                optionValue="name"
              ></p-dropdown>
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobType2"
                *ngIf="xmlFormJobType.invalid && (xmlFormJobType.touched || xmlFormJobType.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a job type
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_branchId2" class="label">Branch ID</label>
              <p-dropdown
                [options]="branches"
                optionLabel="name"
                optionValue="id"
                class="w-full"
                placeholder="Select Branch ID"
                formControlName="branch_Id"
                id="jobInput_branchId2"
              ></p-dropdown>
              <label
                id="branchId-help"
                class="p-invalid"
                for="jobInput_branchId2"
                *ngIf="
                  newJobXML.get('branch_Id').invalid &&
                  (newJobXML.get('branch_Id').touched || newJobXML.get('branch_Id').dirty)
                "
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a Branch ID
              </label>
            </div>
            <div class="pt-4">
              <p-checkbox
                inputId="jobInput_processManuallyXml"
                name="groupname"
                [binary]="true"
                formControlName="processManually"
              ></p-checkbox>
              <label for="jobInput_processManuallyXml" class="label_check">Process Manually</label>
              <p class="text-xs max-w-24rem pt-2 text-light">
                By choosing this option, your job/order will be sent to RoofingWRX for manual processing and
                verification of the output.
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <file-drop-input
              [id]="'jobInput_fileXML'"
              name="jobInput_fileXML"
              accept=".xml"
              acceptText="XML"
              label="XML file"
              (change)="fileUpload($event)"
              (drop)="drop($event)"
              [fileList]="uploadFiles"
              (removeFile)="removeFile($event)"
            ></file-drop-input>
            <label
              id="username2-help"
              class="p-invalid"
              for="jobInput_fileXML"
              *ngIf="step1SelectedOption === 'option2' && newJobXML.invalid && uploadFiles.length === 0"
              >Please upload your XML file.
            </label>
            <small class="inputDescription">
              The XML file must be formatted with 3D point, edge, and face information. XML takeoffs from EagleView,
              TopView (v9.19 or later) and Hover are also compatible.
            </small>
          </div>
        </div>
      </section>

      <section *ngIf="step1SelectedOption === 'option3'">
        <h3 class="srOnly">Blueprints settings</h3>
        <div class="grid">
          <div class="col-12 md:col-6" [formGroup]="newJobBluePrint">
            <div class="inputCont">
              <label for="jobInput_jobName3" class="label">Job name</label>
              <input
                pInputText
                id="jobInput_jobName3"
                type="text"
                name="jobName"
                class="w-full"
                placeholder="Job name"
                formControlName="name"
                (blur)="nameValidation('Blueprints')"
                (input)="jobNameExists = false"
              />
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobName3"
                *ngIf="bluePrintsFormJobName.invalid && (bluePrintsFormJobName.touched || bluePrintsFormJobName.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                <ng-container *ngIf="bluePrintsFormJobName.errors?.maxlength; else defaultError">
                  Job name should not exceed 29 characters.
                </ng-container>
                <ng-template #defaultError> Please enter a job name </ng-template>
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_jobType1" class="label">Job Type</label>
              <p-dropdown
                [options]="jobTypes"
                optionLabel="name"
                class="w-full"
                placeholder="Select job type"
                formControlName="jobType"
                id="jobInput_jobType1"
                optionValue="name"
              ></p-dropdown>
              <label
                id="username2-help"
                class="p-invalid"
                for="jobInput_jobType1"
                *ngIf="bluePrintsFormJobType.invalid && (bluePrintsFormJobType.touched || bluePrintsFormJobType.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a job type
              </label>
            </div>
            <div class="inputCont">
              <label for="jobInput_branchId3" class="label">Branch ID</label>
              <p-dropdown
                [options]="branches"
                optionLabel="name"
                optionValue="id"
                class="w-full"
                placeholder="Select Branch ID"
                formControlName="branch_Id"
                id="jobInput_branchId3"
              ></p-dropdown>
              <label
                id="branchId-help"
                class="p-invalid"
                for="jobInput_branchId3"
                *ngIf="bluePrintsBranchId.invalid && (bluePrintsBranchId.touched || bluePrintsBranchId.dirty)"
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a Branch ID
              </label>
            </div>
            <div class="inputCont">
              <p class="label">Pricing model</p>
              <div class="inputCont_check">
                <p-radioButton
                  inputId="jobInput_structure_single"
                  name="pricingmodel"
                  formControlName="pricingmodel"
                  value="Single"
                ></p-radioButton>
                <label for="jobInput_structure_single" class="label_check">Single structure</label>
              </div>
              <div class="inputCont_check">
                <p-radioButton
                  inputId="jobInput_structure_multiple"
                  name="pricingmodel"
                  formControlName="pricingmodel"
                  value="Multi"
                ></p-radioButton>
                <label for="jobInput_structure_multiple" class="label_check">Multiple structures</label>
              </div>
              <p
                id="username2-help"
                class="p-invalid"
                *ngIf="
                  bluePrintsPricingmodel.invalid && (bluePrintsPricingmodel.touched || bluePrintsPricingmodel.dirty)
                "
              >
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
                Please select a pricing model
              </p>
            </div>
            <div class="inputCont">
              <label for="jobInput_coments" class="label">Comments</label>
              <textarea
                pInputTextarea
                id="jobInput_coments"
                class="w-full"
                placeholder="Comments"
                formControlName="comments"
              ></textarea>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <file-drop-input
              [id]="'jobInput_fileXML'"
              name="jobInput_fileXML"
              [multiple]="true"
              accept=".pdf,image/png,image/jpeg,.xml"
              acceptText="PDF, JPG, PNG, XML"
              label="Project files"
              (change)="fileUpload($event)"
              (drop)="drop($event)"
              [fileList]="uploadFiles"
              (removeFile)="removeFile($event)"
            ></file-drop-input>
            <label
              id="username2-help"
              for="jobInput_fileXML"
              class="p-invalid"
              *ngIf="step1SelectedOption === 'option3' && newJobBluePrint.invalid && uploadFiles.length === 0"
              >Please upload your files.
            </label>
            <small class="inputDescription">
              Please include as many helpful documents as possible so your order can be processed in a timely manner.
              Valid documents may include:
              <ul>
                <li>Dimensioned Sketches (Slope or Ridge to Eave measurements included).</li>
                <li>Architectural Plans (Roof Plan, Dimensioned floor Plan and Elevations at a minimum).</li>
                <li>Aerial Photos (with a scale and all slopes included).</li>
                <li>Satellite Imagery (with a scale and all slopes included).</li>
              </ul>
            </small>
          </div>
        </div>
      </section>
      <alert
        *ngIf="isStep1Invalid()"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t set data source'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <alert
        *ngIf="showSearchResultMessageError === true"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t locate Address'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>

      <alert
        *ngIf="jobNameExists"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Job name already exists'"
        [description]="'You already have another job with the same name. Please enter a new job name and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <div class="divider mt-4 mb-4"></div>
      <section class="footActionsCont spaced">
        <button
          pButton
          [disabled]="jobNameExists || (!isEVPaymentSetupValid && step1SelectedOption === 'option1')"
          type="button"
          class="fullMobile"
          (click)="changeStep(0, isProvidersStepHidden ? 2 : 1, false, true)"
        >
          Next
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
      </section>
    </step-card>

    <!-- Step 2 -->
    <step-card
      [hidden]="isProvidersStepHidden"
      id="jobStep_2"
      number="2"
      label="Providers"
      [selected]="activeStep[1]"
      [textSelected]="'Choose data providers for this job:'"
      [completed]="completedStep[1]"
      [textCompleted]="getTextCompletedStep2()"
    >
      <section class="mb-4">
        <option-box
          [id]="'jobProvidersOption_ev'"
          [isRadio]="false"
          [value]="true"
          [disabled]="false"
          [checked]="false"
          image="assets/img/icons/source_ev.png"
          label="EagleView"
          [styleClass]="'borderBottom_0'"
          [(ngModel)]="step2SelectedOption.option1"
          (optionSelected)="step2SelectedOption.option1 = !step2SelectedOption.option1"
        >
          <span
            >With only an address, you can obtain dimensions data from any location based on accurate aerial views
            provided by EagleView.</span
          >
          <span *ngIf="step2SelectedOption.option1 === true" class="optionInfoText">
            <fa-icon [icon]="faInfoCircle"></fa-icon>
            This provider is required by your selected data source (Address).
          </span>
          <span *ngIf="step2SelectedOption.option2 === true" class="optionInfoText">
            <fa-icon [icon]="faInfoCircle"></fa-icon>
            Your selected data source (XML File) is not compatible with this provider.
          </span>
        </option-box>
        <app-service-item
          [label]="'EagleView'"
          [description]="
            'With only an address, you can obtain dimensions data from any location based on accurate aerial views provided by EagleView.'
          "
          [disclaimer]="'At this time, EagleView provides data for buildings in the United States and Canada only.'"
          [logo]="'assets/img/icons/source_ev.png'"
          [account]="'account'"
          [isServiceAvailable]="true"
          [disabled]="false"
          [isCompact]="true"
          [isLogoVisible]="false"
        >
        </app-service-item>
      </section>

      <section>
        <option-box
          [id]="'jobProvidersOption_rw'"
          [isRadio]="false"
          [checked]="false"
          (optionSelected)="toggleRoofingWRXonly($event)"
          image="assets/img/icons/source_rw.png"
          label="Roofing WRX"
          [styleClass]="'borderBottom_0'"
          [value]="true"
          [(ngModel)]="step2SelectedOption.option2"
        >
          <span
            >Get accurate 3D models and measurements from any type of drawing, like architect drawings, napkin sketches,
            and more.</span
          >
        </option-box>
        <app-service-item
          [label]="'Roofing WRX'"
          [logo]="'assets/img/icons/source_rw.png'"
          [account]="'account'"
          [isServiceAvailable]="isRoofingWRXActive === true"
          [isCompact]="true"
          [isLogoVisible]="false"
          [styleClass]="'borderBottom_0'"
        >
        </app-service-item>
        <p-accordion *ngIf="isRoofingWRXActive === true && isRoofingWRXonly === true" styleClass="accordion_compact">
          <p-accordionTab header="Parameters">
            <div class="grid" [formGroup]="newJobRoofingWRX">
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_1" class="label">Coverage width</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_1"
                      type="text"
                      placeholder="Coverage width"
                      class="w-full"
                      formControlName="coverageWidth"
                    />
                    <i class="inputIcon parameterInputIcon">in</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_2" class="label">Ridge offest</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_2"
                      type="text"
                      placeholder="Ridge offest"
                      class="w-full"
                      formControlName="ridgeOffset"
                    />
                    <i class="inputIcon parameterInputIcon">in</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_3" class="label">Hip offset</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_3"
                      type="text"
                      placeholder="Hip offset"
                      class="w-full"
                      formControlName="hipOffset"
                    />
                    <i class="inputIcon parameterInputIcon">in</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_4" class="label">Valley offset</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_4"
                      type="text"
                      placeholder="Valley offset"
                      class="w-full"
                      formControlName="valleyOffset"
                    />
                    <i class="inputIcon parameterInputIcon">in</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_5" class="label">Eave offset</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_5"
                      type="text"
                      placeholder="Eave offset"
                      class="w-full"
                      formControlName="eaveOffset"
                    />
                    <i class="inputIcon parameterInputIcon">in</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_6" class="label">Max length</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_6"
                      type="text"
                      placeholder="Max length"
                      class="w-full"
                      formControlName="maxLength"
                    />
                    <i class="inputIcon parameterInputIcon">ft</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_7" class="label">Overlap</label>
                  <div class="inputIconCont right">
                    <input
                      pInputText
                      id="jobInputRW_parameter_7"
                      type="text"
                      placeholder="Overlap"
                      class="w-full"
                      formControlName="overlap"
                    />
                    <i class="inputIcon parameterInputIcon">in</i>
                  </div>
                </div>
              </div>
              <div class="col-6 lg:col-3">
                <div class="inputCont">
                  <label for="jobInputRW_parameter_8" class="label">Panel type</label>
                  <p-dropdown
                    [options]="panelTypes"
                    optionLabel="displayName"
                    class="w-full"
                    placeholder="Select panel type"
                    formControlName="panelType"
                    optionValue="name"
                  ></p-dropdown>
                </div>
              </div>
            </div>
            <button pButton type="button" class="default outline small">Reset to default</button>
          </p-accordionTab>
        </p-accordion>
      </section>
      <alert
        *ngIf="step2HasError"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t set providers'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <div class="divider mt-0 mb-4"></div>
      <section class="footActionsCont spaced">
        <button pButton type="button" class="fullMobile" (click)="changeStep(1, 2, false, true)">
          Next
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
        <button pButton class="default outline fullMobile" type="button" (click)="changeStep(1, 0, true)">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
          Go back
        </button>
      </section>
    </step-card>

    <!-- Step 3 -->
    <step-card
      id="jobStep_3"
      [number]="isProvidersStepHidden ? 2 : 3"
      label="Summary"
      [selected]="activeStep[2]"
      [textSelected]="'Please review your job and add any necessary information:'"
      [completed]="completedStep[2]"
      [textCompleted]="'Completed'"
    >
      <h2 class="srOnly">Summary</h2>

      <section>
        <div *ngIf="step1SelectedOption === 'option1'" class="inputCont">
          <label for="jobInput_code" class="label">Promotional code (optional)</label>
          <input pInputText type="text" id="jobInput_code" placeholder="Enter code" />
        </div>
        <div class="inputCont">
          <label for="jobInput_notes" class="label">Notes</label>
          <textarea
            pInputTextarea
            id="jobInput_notes"
            class="w-full"
            placeholder="Enter additional details"
            [(ngModel)]="order.jobComments"
          ></textarea>
        </div>
        <alert
          *ngIf="representedUser && representedUser.name !== null"
          color="main"
          [icon]="faUser"
          label="{{ representedUser.name }} will be the owner of this job"
          description="You're about to create a job in behalf of another user ({{
            representedUser.name
          }}). This user will see this job in their account and have full control of it. Please make sure the user you entered is correct and that you're not sharing sensitive information."
          styleClass="mb-4"
        >
        </alert>
      </section>
      <alert
        *ngIf="step4HasError"
        color="danger"
        [icon]="faTriangleExclamation"
        [label]="'Couldn\'t create job'"
        [description]="'Please check the marked fields and try again.'"
        [styleClass]="'mt-2'"
      >
      </alert>
      <div class="divider mb-4"></div>
      <section class="footActionsCont spaced">
        <button pButton type="button" class="success fullMobile" (click)="createJob()">
          <fa-icon [icon]="faCheck"></fa-icon>
          Create job
        </button>
        <button
          pButton
          type="button"
          class="outline default fullMobile"
          (click)="changeStep(2, isProvidersStepHidden ? 0 : 1, true)"
        >
          <fa-icon [icon]="faArrowLeft"></fa-icon>
          Go back
        </button>
      </section>
    </step-card>
  </p-accordion>
</div>
