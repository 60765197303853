import { Component, OnInit } from "@angular/core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ManufacturerProfile } from "src/app/core/store/app.models";
import { AppState } from "src/app/shared/services/app-state";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/shared/services/profile.service";

@Component({
  selector: "app-select-company",
  templateUrl: "./select-company.component.html",
  styleUrls: ["./select-company.component.scss"],
})
export class SelectCompanyComponent implements OnInit {
  profiles: ManufacturerProfile[] = [];

  faArrowRight = faArrowRight;
  
  constructor(
    private appState: AppState,
    private router: Router,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.appState.appDataOnce$.subscribe(
      (data: any) =>
        (this.profiles = data.profiles.filter(
          (x) =>
            (x.roofingWRXEnabled && x.roofingWRXJobsEnabled && x.allRegistrationCompleted) ||
            x.nonWRXRegistrationCompleted
        ))
    );
  }

  selectProfile(profile: ManufacturerProfile) {
    this.profileService.chooseProfile(profile.manufacturer.id).subscribe(() => {
      this.router.navigate(["jobs"]);
    });
  }
}
