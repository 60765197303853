import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { NgxsModule } from "@ngxs/store";
import { CoreModule } from "src/app/core/core.module";
import { DemoRoutingModule } from "./demo.routing.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { StartComponent } from "./start/start.component";
import { IntroModalComponent } from "./intro-modal/intro-modal.component";
import { CreateJobComponent } from "./create-job/create-job.component";
import { NewJobDemoComponent } from "./new-job-demo/new-job-demo.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PrimengLibraryModule } from "src/app/shared/libraries/primeng-library.module";
import { DialogFirstStepComponent } from "./dialog-first-step/dialog-first-step.component";
import { EagleviewComponent as EagleviewFirstStepComponent } from "./dialog-first-step/eagleview/eagleview.component";
import { XmlComponent as XmlFirstStepComponent } from "./dialog-first-step/xml/xml.component";
import { BlueprintsComponent as BlueprintsFirstStepComponent } from "./dialog-first-step/blueprints/blueprints.component";
import { DialogSecondStepComponent } from "./dialog-second-step/dialog-second-step.component";
import { EagleviewComponent as EagleviewSecondStepComponent } from "./dialog-second-step/eagleview/eagleview.component";
import { XmlComponent as XmlSecondStepComponent } from "./dialog-second-step/xml/xml.component";
import { BlueprintsComponent as BlueprintsSecondStepComponent } from "./dialog-second-step/blueprints/blueprints.component";
import { DialogThirdStepComponent } from "./dialog-third-step/dialog-third-step.component";
import { EagleviewComponent as EagleviewThirdStepComponent } from "./dialog-third-step/eagleview/eagleview.component";
import { XmlComponent as XmlThirdStepComponent } from "./dialog-third-step/xml/xml.component";
import { BlueprintsComponent as BlueprintsThirdStepComponent } from "./dialog-third-step/blueprints/blueprints.component";
import { CreateJobSuccessComponent } from "./create-job-success/create-job-success.component";

@NgModule({
  declarations: [
    StartComponent,
    IntroModalComponent,
    CreateJobComponent,
    NewJobDemoComponent,
    DialogFirstStepComponent,
    EagleviewFirstStepComponent,
    XmlFirstStepComponent,
    BlueprintsFirstStepComponent,
    DialogSecondStepComponent,
    EagleviewSecondStepComponent,
    XmlSecondStepComponent,
    BlueprintsSecondStepComponent,
    DialogThirdStepComponent,
    EagleviewThirdStepComponent,
    XmlThirdStepComponent,
    CreateJobSuccessComponent,
    BlueprintsThirdStepComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    DemoRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengLibraryModule,
    NgxsModule.forFeature([]),
  ],
  providers: [],
})
export class DemoModule {}
