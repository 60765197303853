import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {AppState} from './app-state';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ApiEndpoints} from '../constants/api-endpoints';
import {GetCompaniesResponse} from "../../core/data/models/GetCompaniesResponse";
import {GetCompanyResponse} from "../../core/data/models/GetCompanyResponse";
import {GetCompanyNamesResponse} from "../../core/data/models/GetCompanyNamesResponse";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
  ) {
  }

  getCompanies(pageIndex?: number, pageSize?: number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    let params = new HttpParams();
    params = params.append("pageIndex", pageIndex);
    params = params.append("pageSize", pageSize);

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getCompanies}`;
    return this.httpClient.get<PaginatedResponse<GetCompaniesResponse>>(url, {headers: headers, params});
  }

  getCompanyNames() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getCompanyNames}`;
    return this.httpClient.get<GetCompanyNamesResponse[]>(url, {headers: headers});
  }

  getCompany(companyId: number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getCompany(companyId)}`;
    return this.httpClient.get<GetCompanyResponse>(url, {headers: headers});
  }
}
