import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import {ReplaySubject, Subscription} from "rxjs";

import {ICustomWindow, WindowRefService} from "../shared/helpers/window-ref.service";
import {AppInitializationData, ProfileStatus} from "../core/data/models/AppInitializationData";
import {UserSetting} from "../core/data/models/user-setting";
import {CookieService} from "ngx-cookie-service";
import {AppInitializerService} from "../shared/services/app-initializer.service";
import {AppState} from "../shared/services/app-state";
import {AuthenticationService, AuthContext} from "../shared/services/authentication.service";
import {IdleService} from "../shared/services/idle.service";
import {ProfileService} from "../shared/services/profile.service";
import {ConfirmService} from "../core/confirm/confirm.service";
import {DEFAULT_INTERRUPTSOURCES, Idle} from "@ng-idle/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  showRouterOutput = false;
  busy: Subscription;
  idleTimerLeft: string;
  totalTimeoutWarnings: number = 0;
  configs = this.appState.configs;
  userSettings: UserSetting[] = [];
  private window: ICustomWindow;
  private clientBaseUrl = encodeURIComponent(this.appState.configs.ClientBaseUrl);
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private isInitialized: boolean = false;
  isRpUnderMantainance: boolean = false;

  get claims() {
    if (this.authService.isAuthenticated(AuthContext.Azure)) {
      return this.authService.claims(AuthContext.Azure);
    }
    return false;
  }

  get isDemo() {
    return this.router.url.includes("/demo");
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private appState: AppState,
    private windowRefService: WindowRefService,
    private appInitializerService: AppInitializerService,
    private profileService: ProfileService,
    private confirmService: ConfirmService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private idleService: IdleService,
    private cookieService: CookieService
  ) {
    idle.setIdle(900);
    idle.setTimeout(1500);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleStart.subscribe(() => {
      this.idleService.startTimeoutWarningTimer();
      this.confirmService.confirmEnhanced(
        "Session Timeout",
        "Your session is about to expire due to inactivity.",
        "pi pi-clock",
        () => {
          this.idleService.stopTimeoutWarningTimer();
          this.confirmService.close();
        },
        () => {
          this.logout();
        },
        "p-button-primary",
        "p-button-secondary",
        "Continue",
        "Log Out"
      );
    });

    idle.onIdleEnd.subscribe(() => {
      cd.detectChanges();
    });

    idle.onTimeout.subscribe(() => {
      this.logout();
    });

    this.window = this.windowRefService.nativeWindow;
  }

  ngOnInit() {
    this.configure();
    this.setStates();

    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        if (!this.isInitialized) {
          this.isInitialized = true;
          this.authService.checkAuth(AuthContext.Azure).subscribe(() => {
            if (this.authService.isAuthenticated(AuthContext.Azure)) {
              return this.appInitializerService.getAppInitialData().subscribe(data => this.handleAppDataInit(data));
            } else {
              this.login();
            }
          });
        }
      }
    });
  }

  setStates() {
    this.idle.watch();
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  private configure() {
    const clientBaseUrl = encodeURIComponent(this.appState.configs.ClientBaseUrl);
    this.appState.configs.AccountUrl = this.appState.configs.AccountUrl.replace(
      "[B2cClientID]",
      this.appState.configs.B2cClientID
    ).replace("[ClientBaseUrl]", clientBaseUrl);
  }

  public login() {
    this.authService.login(AuthContext.Azure);
  }

  public logout() {
    this.cookieService.deleteAll();
    this.authService.logout(AuthContext.Azure);
  }

  private handleAppDataInit(data) {
    if (data == null) {
      console.log("null App initialization data");
    } else if (
      !data.profiles.find((x) => x.status === ProfileStatus.Accepted) &&
      !data.profiles.find((x) => x.status === ProfileStatus.Pending)
    ) {
      this.confirmService.confirmEnhanced(
        "Inactive User",
        "Your Roofing Passport record is not active.  Please contact your administrator.",
        "pi pi-user",
        () => {
          this.logout();
        },
        () => {
        },
        "p-button-primary",
        "",
        "Ok",
        "",
        "",
        "",
        ""
      );
    } else if (this.appState.needsCurrentProfile) {
      return this.navigateToBase(data);
    } else {
      return this.profileService.validateProfile().subscribe(() => {
        return this.navigateToBase(data);
      });
    }
  }

  private navigateToBase(data: AppInitializationData) {
    this.showRouterOutput = true;

    if (this.appState.hasNewRegistration()) {
      return this.router.navigate(["register-account"]);
    } else if (this.appState.needsCurrentProfile) {
      return this.router.navigate(["select-company"]);
    } else if (this.router.url && this.router.url !== "/register-account") {
      return this.router.navigate([this.router.url]);
    } else {
      return this.router.navigate(["jobs"]);
    }
  }
}
