import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {Injectable, NgZone} from "@angular/core";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AppState} from "./app-state";
import {AuthenticationService} from "./authentication.service";
import {saveAs} from "file-saver";
import {Manufacturer} from "src/app/core/store/app.models";
import {ApiEndpoints} from "../constants/api-endpoints";
import {runInZone} from "../pipes/run-in-zone.pipe";
import {UpdateCompanyRequest} from "../../core/data/models/UpdateCompanyRequest";
import {GetCompaniesResponse} from "../../core/data/models/GetCompaniesResponse";
import {GetUsersResponse} from "../../core/data/models/GetUsersResponse";
import {GetCompanyResponse} from "../../core/data/models/GetCompanyResponse";
import {GetUserResponse} from "../../core/data/models/GetUserResponse";
import {UpdateUserRequest} from "../../core/data/models/UpdateUserRequest";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(
    private httpClient: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {
  }

  public updateCompany(
    manufacturer: Manufacturer
  ): Observable<{ manufacturer: Manufacturer; error: HttpErrorResponse }> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.updateCompany}`;
    return this.httpClient.post<UpdateCompanyRequest>(url, manufacturer, {headers}).pipe(
      runInZone(this.ngZone),
      map((response) => ({manufacturer: response, error: null})),
      catchError((err: HttpErrorResponse) =>
        of({
          manufacturer: null,
          error: err,
        })
      )
    );
  }

  public downloadUserReport(manufacturerId: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.downloadUserReport(manufacturerId)}`;
    return this.httpClient.get(url, {headers, observe: "response", responseType: "blob"}).pipe(
      runInZone(this.ngZone),
      map((res) => {
        this.saveBlobResponse(res);
        return of(res.body);
      })
    );
  }

  downloadAdminUserGuide(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });
    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.downloadAdminUserGuide}`;
    return this.httpClient.get(url, {headers, observe: "response", responseType: "blob"}).pipe(
      runInZone(this.ngZone),
      map((res) => {
        this.saveBlobResponse(res);
        return of(res.body);
      })
    );
  }

  public getUsers(companyId: number, pageIndex?: number, pageSize?: number): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    let params = new HttpParams();
    params = params.append("companyId", companyId);
    params = params.append("pageIndex", pageIndex);
    params = params.append("pageSize", pageSize);

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getUsers}`;
    return this.httpClient.get<PaginatedResponse<GetUsersResponse>>(url, {headers: headers, params});
  }

  getUser(userId: number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getUser(userId)}`;
    return this.httpClient.get<GetUserResponse>(url, {headers: headers});
  }

  updateUser(request: UpdateUserRequest) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.updateUser}`;
    return this.httpClient.post(url, request, {headers: headers});
  }

  private saveBlobResponse(response: HttpResponse<Blob>) {
    const disposition = response.headers.get("Content-Disposition");
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    const filename = matches?.length > 0 ? matches[1].replace(/['"]/g, "") : "user_export.csv";
    saveAs(response.body, filename);
  }
}
