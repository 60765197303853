import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Order, OrderStatistics } from "src/app/core/data/models/Reporting";
import { ApiEndpoints } from "../constants/api-endpoints";
import { AppState } from "./app-state";
import { AuthenticationService } from "./authentication.service";

interface OrderFilter {
  fromDate: string;
}

@Injectable({
  providedIn: "root",
})
export class ReportingService {
  constructor(
    private http: HttpClient,
    private appState: AppState,
    private authService: AuthenticationService
  ) { }

  getOrdersByFilter(filter: OrderFilter, pageIndex?: number, pageSize?: number): Observable<Order[]> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    let params = new HttpParams();
    params = params.append("pageIndex", pageIndex);
    params = params.append("pageSize", pageSize);

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getOrdersByFilter}`;

    return this.http.post<Order[]>(url, filter, { headers, params });
  }

  getOrderStatistics(): Observable<OrderStatistics> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${this.authService.accessToken()}`,
      "X-RP-Profile-Id": this.appState.companyId.toString(),
    });

    const url = `${this.appState.configs.ServicesBaseUrl}${ApiEndpoints.getOrderStatistics}`;

    return this.http.get<OrderStatistics>(url, { headers });
  }
}
