<input
  [type]="isRadio ? 'radio' : 'checkbox'"
  [name]="name"
  [attr.name]="name"
  [value]="value"
  [(ngModel)]="selectedValue"
  [id]="id"
  [checked]="checked"
  [disabled]="disabled"
  class="srOnly optionBox__input"
  (click)="selectOption()"
/>
<label
  [for]="id"
  class="optionBox {{ styleClass }}"
  [ngClass]="{ optionBox_smallTitle: smallTitle }"
>
  <div class="optionBox__header">
    <div class="optionBox__titleCont">
      <fa-icon
        *ngIf="icon !== null"
        [icon]="icon"
        class="optionBox__icon ml-2 mr-2"
        [ngClass]="{
          main: iconColor === 'main',
          success: iconColor === 'success',
          warning: iconColor === 'warning',
          danger: iconColor === 'danger',
          mono: iconColor === 'mono'
        }"
      >
      </fa-icon>
      <img *ngIf="image !== null" [src]="image" alt="" class="optionBox__image" />
      <span class="optionBox__title" [ngClass]="{ hasSubtitle: subtitle }">
        {{ label }}
        <span class="optionBox__subtitle">{{ subtitle }}</span>
      </span>
    </div>
    <span class="optionBox__fakeInput" [ngClass]="isRadio ? 'radio' : 'checkbox'">
      <fa-icon *ngIf="!isRadio" class="optionBox__fakeInput__icon" [icon]="faCheck"></fa-icon>
    </span>
  </div>
  <div class="optionBox__content">
    <ng-content></ng-content>
  </div>
</label>
