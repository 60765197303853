<section class="container">
  <div class="pageHeading">
    <div>
      <h2 class="title2">Which company will you work with?</h2>
    </div>
  </div>

  <ul class="companySelectionList">
    <li *ngFor="let profile of profiles; let i = index">
      <button (click)="selectProfile(profile)" class="unstyledButton companySelectionBtn">
        <span class="companySelectionBtn__labelCont">
          <img src="assets/img/placeholder/companyProfilePicPlaceholder.png" alt="" class="companySelectionBtn__profileImg">
          <span class="companySelectionBtn__label">{{ profile.manufacturer.name }}</span>
        </span>
        <span class="companySelectionBtn__iconCont">
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </span>
      </button>
    </li>
  </ul>
</section>
