<div class="flex flex-column" *ngFor="let service of services">
  <div
    *ngIf="service.status === 'Valid'"
    class="border-1 border-outline mt-4 flex flex-column row-gap-3 lg:row-gap-0 lg:flex-row justify-content-between p-3 md:p-4"
  >
    <div class="flex align-items-start lg:align-items-center">
      <img class="w-3rem mt-3 md:mt-0" [src]="service.logo" [alt]="service.title" />
      <div class="pl-3 md:pl-5">
        <h4 class="text-base lg:text-lg font-bold pb-2">{{ service.title }}</h4>
        <p class="text-xs lg:text-sm max-w-30rem">
          {{ service.description }}
        </p>
      </div>
    </div>
    <div class="flex flex-column align-items-end justify-content-center row-gap-3">
      <button
        class="w-3rem h-3rem flex align-items-center justify-content-center border-1 border-main cursor-pointer bg-white"
        (click)="logout(service)"
      >
        <i class="pi pi-times font-bold"></i>
      </button>
      <p class="text-xs lg:text-sm">{{ service.claims["email"] }}</p>
    </div>
  </div>
  <app-service-item
    *ngIf="service.status === 'None'"
    [label]="service.title"
    [description]="service.description"
    [disclaimer]="service.disclaimer"
    [logo]="service.logo"
    [learnMoreLink]="service.learnMoreLink"
    [isServiceAvailable]="service.isAvailable"
    (addAccountClick)="openAddAccountModal(service.title)"
    [styleClass]="'mt-4'"
  ></app-service-item>
</div>
<app-add-account-modal
  [(isVisible)]="isAddAccountModalOpen"
  [(partnerSystem)]="partnerSystem"
  [(authorization)]="authorization"
  (onClose)="mapServices.emit()"
></app-add-account-modal>
